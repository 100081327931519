


(async () => {
  // platform might be needed for other modules
  const { loadPlatform } = await import("./frontend-utils/platform.js");

  //const { loadGoogleAuth } = await import("./login/google-auth.js");

  await loadPlatform();
  //await loadGoogleAuth();

  await import("./main-chatbot-demo.js");
})();
